<template>
  <div style="white-space: break-spaces">
    <!-- <span
      v-dompurify-html="
        channel.toLowerCase() === 'webchat' ? formattedMessage : message.text
      "
      class="qchat-conv__bubble__message"
    ></span> -->
    <span v-for="(content, idx) in contentFormatter(message.text)" :key="idx">
      <span
        v-dompurify-html="bracketClear(whatsappFormatter(content.textBefore))"
      ></span
      ><a
        :href="
          content.url.includes('://') ? content.url : `https://${content.url}`
        "
        style="word-break: break-all;"
        target="_blank"
        >{{ content.url }}</a
      ><span
        v-dompurify-html="bracketClear(whatsappFormatter(content.textAfter))"
      ></span>
    </span>
    <span
      v-if="message.text.length >= 253 && !isRead"
      class="q-pointer"
      @click="isRead = true"
      :style="{
        color:
          styles.conversation.bubbleChat[
            user.name === message.sender.name ? 'right' : 'left'
          ].mainColor === '#0474f5'
            ? '#234d8d'
            : '#0274f5'
      }"
      >Read more</span
    >
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    channel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isRead: true
    };
  },
  computed: {
    ...mapState("messenger", ["styles", "user"]),
    formattedMessage() {
      if (this.isRead)
        return this.whatsappToPreviewFormatter(this.message.text);
      else
        return this.whatsappToPreviewFormatter(
          this.sliceWords(this.message.text, 253)
        );
    }
  }
};
</script>
