var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"white-space":"break-spaces"}},[_vm._l((_vm.contentFormatter(_vm.message.text)),function(content,idx){return _c('span',{key:idx},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.bracketClear(_vm.whatsappFormatter(content.textBefore))),expression:"bracketClear(whatsappFormatter(content.textBefore))"}]}),_c('a',{staticStyle:{"word-break":"break-all"},attrs:{"href":content.url.includes('://') ? content.url : ("https://" + (content.url)),"target":"_blank"}},[_vm._v(_vm._s(content.url))]),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.bracketClear(_vm.whatsappFormatter(content.textAfter))),expression:"bracketClear(whatsappFormatter(content.textAfter))"}]})])}),(_vm.message.text.length >= 253 && !_vm.isRead)?_c('span',{staticClass:"q-pointer",style:({
      color:
        _vm.styles.conversation.bubbleChat[
          _vm.user.name === _vm.message.sender.name ? 'right' : 'left'
        ].mainColor === '#0474f5'
          ? '#234d8d'
          : '#0274f5'
    }),on:{"click":function($event){_vm.isRead = true}}},[_vm._v("Read more")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }